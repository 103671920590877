import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import useUserDetails from '../hooks/api/users/useUserDetails';
import { useLogout } from '../hooks/useLogout';
import useToast from '../hooks/useToast';
import ActivateAccountView from '../pages/activateAccount/ActivateAccountView';
import CreatePasswordView from '../pages/createPassword/CreatePasswordView';
import GamePreview from '../pages/gamePreview/GamePreview';
import LoginView from '../pages/login/LoginView';
import PrivacyPolicyView from '../pages/privacyPolicy/PrivacyPolicyView';
import RegulationsView from '../pages/regulations/RegulationsView';
import RequestResetPasswordView from '../pages/requestResetPassword/RequestResetPasswordView';
import ResetPasswordView from '../pages/resetPassword/ResetPasswordView';
import { paths, routes } from '../static/routes';
import { UserType, UserTypes } from '../types/user/userTypes';
import GlobalAdminRoutes from './GlobalAdminRoutes';
import PartnerRoutes from './PartnerRoutes';

const routeOptions = [
  {
    userType: UserTypes.GLOBAL_ADMIN,
    element: GlobalAdminRoutes(),
  },
  {
    userType: UserTypes.ADMINISTRATION_MANAGER,
    //TODO: use AdministrationManagerRoutes.tsx or delete the file if not needed
    element: GlobalAdminRoutes(),
  },
  {
    userType: UserTypes.PARTNER,
    element: PartnerRoutes(),
  },
  {
    userType: UserTypes.COMPANY_MANAGER,
    //TODO: use CompanyManagerRoutes.tsx or delete the file if not needed
    element: PartnerRoutes(),
  },
];

const getRoutesByUserType = (userType?: UserType) => {
  return routeOptions.find(option => option.userType === userType)?.element;
};

const publicRoutesData = [
  { path: paths.login, element: <LoginView /> },
  { path: paths.requestResetPassword, element: <RequestResetPasswordView /> },
  { path: paths.resetPassword, element: <ResetPasswordView /> },
  { path: paths.createPassword, element: <CreatePasswordView /> },
  { path: paths.gamePreview, element: <GamePreview /> },
  { path: paths.privacyPolicy, element: <PrivacyPolicyView /> },
  { path: paths.regulations, element: <RegulationsView /> },
  { path: paths.activateAccount, element: <ActivateAccountView /> },
];

const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const { handleLogout } = useLogout();

  const shouldGetUserData = !publicRoutesData.some(item => {
    const staticPart = item.path.replace(/:[^/]+/g, ''); // Replace dynamic parameters with a placeholder

    return location.pathname.startsWith(staticPart);
  });

  const { user, isLoading } = useUserDetails({ enabled: shouldGetUserData });
  const userType = user?.role;
  const userTypeRoutes = getRoutesByUserType(userType);

  useEffect(() => {
    if (!user) {
      return;
    }

    const isUserNotAllowed = !routeOptions.some(routeOption => routeOption.userType === user?.role);

    if (isUserNotAllowed) {
      handleLogout();
      showErrorToast(t('errorMessages.accessDenied'));
      navigate(routes.login);
    }
  }, [handleLogout, navigate, showErrorToast, t, user]);

  useEffect(() => {
    if (!isLoading && !user && shouldGetUserData) {
      navigate(routes.login);
    }
  }, [isLoading, navigate, shouldGetUserData, user]);

  if (isLoading) {
    return (
      <div className="h-full min-h-screen flex justify-center items-center">
        <CircularProgress size={50} />
      </div>
    );
  }

  return (
    <Routes>
      {publicRoutesData.map(item => (
        <Route key={item.path} path={item.path} element={item.element} />
      ))}

      {userTypeRoutes}
    </Routes>
  );
};

export default AppRoutes;
