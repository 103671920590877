import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { PrimaryButton } from '../../components/common/buttons/PrimaryButton';
import { Logo } from '../../components/navigation/components/Logo';
import useActivateAccount from '../../hooks/api/auth/useActivateAccount';
import { routes } from '../../static/routes';
import { searchParamsData } from '../../static/searchParamsData';

const ActivateAccountView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get(searchParamsData.userId) ?? '';
  const token = searchParams.get(searchParamsData.token) ?? '';

  useEffect(() => {
    if (!token || !userId) {
      navigate(routes.home);
    }
  }, [token, userId, navigate]);

  const { userData, activateAccount, isLoading } = useActivateAccount(userId);

  const handleActivateAccount = () => {
    activateAccount({ token: token });
  };

  return (
    <div className="px-8 py-8 mx-auto flex flex-col max-w-xl h-screen justify-center">
      <Logo />
      <h1 className="mt-12 text-black700">{t('activateAccount.title')}</h1>

      {userData ? (
        <div>{t('activateAccount.accountActivated')}</div>
      ) : (
        <>
          <p className="my-4 text-black500 font-medium leading-snug">{t('activateAccount.description')}</p>
          <PrimaryButton type="button" disabled={isLoading} loading={isLoading} onClick={handleActivateAccount}>
            {t('activateAccount.submitBtn')}
          </PrimaryButton>
        </>
      )}
    </div>
  );
};

export default ActivateAccountView;
