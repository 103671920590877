import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { api } from '../../../static/api';
import { ApiActivateAccountBody } from '../../../types/ApiTypes';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useActivateAccount = (userId: string) => {
  const axiosInstance = useAxiosInstance();
  const { showSuccessToast, showErrorToast } = useToast();
  const { t } = useTranslation();

  const { data, mutate, isLoading } = useMutation(
    ({ token }: ApiActivateAccountBody) => axiosInstance.post(api.endpoints.ACTIVATE_ACCOUNT(userId), { token }),
    {
      onSuccess: async () => {
        showSuccessToast(t('activateAccount.successMessage'));
      },
      onError: (err: AxiosError) => {
        const errorMessage = getApiErrorMessage(err) ?? t('activateAccount.activateAccountFailed');

        showErrorToast(errorMessage);
      },
    },
  );

  return { activateAccount: mutate, userData: data?.data, isLoading };
};

export default useActivateAccount;
