export const searchParamsData = {
  token: 'token',
  page: 'page',
  team: 'team',
  center: 'center',
  showQuestions: 'showQuestions',
  messageTo: 'messageTo',
  spectatorToken: 'token',
  tagFilter: 'tag',
  questionTypeFilter: 'questionType',
  cityFilter: 'city',
  ownerFilter: 'owner',
  gameCharacterFilter: 'gameCharacter',
  searchFilter: 'search',
  arcadeFilter: 'isArcade',
  gameTypeFilter: 'gameType',
  zoom: 'zoom',
  userId: 'userId',
};
