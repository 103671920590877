import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { getCoordinatesFromString } from '../../../../utils/general/getCoordinatesFromString/getCoordinatesFromString';

type UsePanToCoordinatesParams = {
  mapRef: React.MutableRefObject<google.maps.Map | null>;
};

export function usePanToCoordinates({ mapRef }: UsePanToCoordinatesParams) {
  const { watch } = useFormContext();
  const coordinatesValue = watch('coordinates');

  useEffect(() => {
    const coordinates = getCoordinatesFromString(coordinatesValue ?? '');

    if (coordinates && mapRef.current) {
      mapRef.current.panTo(new google.maps.LatLng(coordinates.lat, coordinates.lng));
    }
  }, [coordinatesValue, mapRef.current]);
}
